<script>


import { required } from "vuelidate/lib/validators";
import Loader from '@/components/general/Loader.vue';

/**
 * Chat component
 */
export default {


  data() {
    return {
      submitted: false,
      processing:false,
      form: {
        message: "",
      },
      username: "Designers",
      member: null,
    };
  },
  props : {
    chatMessagesData : {
      type : [Array],
      default : () => {} 
    },
    ticket : {
     type: [Array,Object],
      default : () => {} 
    }
  },
  validations: {
    form: {
      message: {
        required,
      },
    },
  },

  methods: {
    /**
     * Char form Submit
     */
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.processing=true;
      this.$axios.post("ticket/comment", {
        ticket_id :  this.$route.params.ticket_id,
        sender_id : this.currentUser.id,
        message : this.form.message
      })
      .then(response => {
        let data = response.data;
        const message = data.message;
        const currentDate = new Date();
        this.chatMessagesData.push({
          align: "right",
          name: this.currentUser.name,
          message,
          created_at: this.changeDateTimeToUTCZero(currentDate),
        });
        this.handleScroll();
      })
      .catch(error => {
        this.handleErrorResponse(error.response, "error");
      }).finally(() => {
        this.submitted = false;
        this.processing = false;
        this.form = {};
      }); 
    },
    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop = this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },
  },
};
</script>

<template>

    <div class="d-lg-flex mb-4">
      <!-- end chat-leftsidebar -->
      <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
        <div class="card">
          <div class="p-3 px-lg-4">
            <div class="row">
              <div class="col-md-4 col-6">
                <h2 class="f-22 fw-bold mb-0">Conversation</h2>
              </div>
              <!-- <div class="col-md-8 col-6">
                <ul class="list-inline user-chat-nav text-end mb-0">
                  <li class="list-inline-item">
                    <b-dropdown
                      right
                      menu-class="dropdown-menu-md dropdown-menu-end"
                      variant="white"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-search"></i>
                      </template>

                      <form class="p-2">
                        <div>
                          <input
                            type="text"
                            class="form-control rounded"
                            placeholder="Search..."
                          />
                        </div>
                      </form>
                    </b-dropdown>
                  </li>

                  <li class="list-inline-item">
                    <b-dropdown right toggle-class="nav-btn" variant="white">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <a class="dropdown-item" href="#">Profile</a>
                      <a class="dropdown-item" href="#">Archive</a>
                      <a class="dropdown-item" href="#">Muted</a>
                      <a class="dropdown-item" href="#">Delete</a>
                    </b-dropdown>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <hr class="custom-divider px-3 py-0 mt-0 mx-3" />
          <div class="px-lg-2 chat-users">
            <div class="chat-conversation p-3  chat-minimum-height">
              <div id="containerElement"
                ref="current">
                <ul class="list-unstyled mb-0">

                  <li
                    v-for="data of chatMessagesData"
                    :key="data.id"
                    :class="{ right: `${data.align}` === 'right' }"
                  >
                    <div class="conversation-list">
                      <div class="ctext-wrap">
                        <div class="ctext-wrap-content">
                          <h5 class="font-size-14 conversation-name">
                            <a href="#" class="text-dark text-capitalize">{{ data.name }}</a>
                            <span
                              class="d-inline-block font-size-12 text-muted ms-2"
                              >{{ DateWithMonthName(data.created_at) }}</span
                            >
                          </h5>
                          <p class="mb-0">{{ data.message }}</p>
                        </div>
                        <!-- <b-dropdown class="align-self-start" variant="white">
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-v"></i>
                          </template>
                          <a class="dropdown-item" href="#">Copy</a>
                          <a class="dropdown-item" href="#">Save</a>
                          <a class="dropdown-item" href="#">Forward</a>
                          <a class="dropdown-item" href="#">Delete</a>
                        </b-dropdown> -->
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="p-3 chat-input-section">
            <form @submit.prevent="formSubmit" class="row">
              <div class="col">
                <div class="position-relative">
                  <input
                    type="text"
                    v-model="form.message"
                    class="form-control chat-input rounded"
                    placeholder="Enter Message..."
                    :class="{
                      'is-invalid': submitted && $v.form.message.$error,
                    }"
                    :disabled="ticket.status=='Closed'"
                  />
                  <div
                    v-if="submitted && $v.form.message.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.message.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="submit"
                  :disabled="ticket.status=='Closed' || processing"
                  class="btn primaryColorBg text-white chat-send w-md waves-effect waves-light"
                >
                  <span class="d-none d-sm-inline-block me-2">Send<span v-if="processing"><Loader  :processing="processing"/><i class='mr-2 fas fa-spinner fa-spin'></i></span></span>
                  <i class="mdi mdi-send float-end"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  <!-- End d-lg-flex  -->
</template>
