<!-- @format -->

<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import EmployeeLayout from "@/views/pages/employee/layout/main";
import appConfig from "@/app.config";
import ChatWidget from "@/components/chat/ChatWidget";

/**
 * Add-product component
 */

export default {
  components: {
    Layout,
    PageHeader,
    ChatWidget,
    EmployeeLayout,
  },
  page: {
    title: "Ticket Detail",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.fetchUserTicketData();
    if (this.isTeamMember()) this.currentLayout = "EmployeeLayout";
  },
  data() {
    return {
      checkStatus: "",
      checkPriority: "",
      chatMessagesData: [],
      title: "Ticket Detail",
      ticketImageUrl: null,
      ticket: [],
      currentLayout: "Layout",
      dataForModal: { attachment: null },

      items: [
        {
          text: "Tickets",
          href: "/",
        },
        {
          text: "Ticket Detail",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
    };
  },
  methods: {
    fetchUserTicketData() {
      this.$axios
        .get("ticket/edit/" + this.$route.params.ticket_id)
        .then((response) => {
          this.ticket = response.data.payload;
          if (this.ticket.attachment) {
            this.ticketImageUrl = this.assets_url + this.ticket.attachment;
          }
          if(this.ticket.user == null){
            this.ticket.status = 'Closed';
            this.$axios.patch("ticket/update", this.ticket);
          }

          this.chatMessagesData = this.ticket.comments.map((comment) => {
            if (comment.sender_id == this.currentUser.id) {
              return {
                align: "right",
                name: comment.user.name,
                message: comment.message,
                created_at: comment.created_at,
              };
            } else {
              return {
                align: "left",
                name: comment.user.name,
                message: comment.message,
                created_at: comment.created_at,
              };
            }
          });
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
    ticketAction(event) {
      this.ticket.priority = event.target.value;
      this.$axios
        .patch("ticket/update", this.ticket)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          this.checkPriority = "";
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
    updateStatus(event) {
      this.ticket.status = event.target.value;
      this.$axios
        .patch("ticket/update", this.ticket)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          this.checkStatus = "";
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
    roleToString(roles) {
      let role_array = roles.map((role) => {
        return role.name;
      });
      let string = role_array.toString();
      return string;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <component :is="currentLayout">
    <div class="row align-items-center">
      <div class="col-md-6">
        <PageHeader :title="title" />
      </div>
      <div class="col-md-6">
        <div
          class="d-flex gap-3 justify-content-end"
          v-if="!isTeamMember() && ticket.status != 'Closed'"
        >
          <select
            v-model="checkPriority"
            class="form-select x-selection shadow"
            @change="ticketAction($event)"
          >
            <option disabled value="">Please Change Priority</option>
            <option value="Low">Low</option>
            <option value="Normal">Normal</option>
            <option value="High">High</option>
            <option value="Emergency">Emergency</option>
          </select>
          <select
            v-model="checkStatus"
            class="form-select x-selection shadow"
            @change="updateStatus($event)"
          >
            <option disabled value="">Please Change Status</option>
            <option>Open</option>
            <option>In Progress</option>
            <option>Resolved</option>
            <option>Closed</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card border">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-md-12 mb-0">
            <h2 class="f-22 fw-bold mb-0">
              Topic: {{ getSafe(() => ticket.topic) }}
            </h2>
          </div>
        </div>
        <hr class="custom-divider" />
        <div class="row">
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Ticket Id</h2>
              <p class="f-16 text-muted mb-0">{{ getSafe(() => ticket.id) }}</p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Ticket Status</h2>
              <p
                class="f-16 text-muted text-capitalize mb-0 status-style py-1 px-3 rounded-pill"
              >
                {{ ticket.status }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">User</h2>
              <p class="f-16 text-muted text-capitalize mb-0">
                {{ getSafe(() => ticket.user.name) }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">User Role</h2>
              <p class="f-16 text-muted text-capitalize mb-0">
                {{
                  getSafe(() => roleToString(ticket.user.employee.area_roles))
                }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Email</h2>
              <p class="f-16 text-muted mb-0">
                {{ getSafe(() => ticket.user.email) }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Priority</h2>
              <p class="f-16 text-muted text-capitalize mb-0 text-danger">
                {{ getSafe(() => ticket.priority) }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Created Date</h2>
              <p class="f-16 text-muted mb-0">
                {{ DateWithMonthName(ticket.created_at) }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Closed Date</h2>
              <p class="f-16 text-muted mb-0" v-if="ticket.status == 'Closed'">
                {{ DateWithMonthName(ticket.updated_at) }}
              </p>
              <p class="f-16 text-muted mb-0" v-else>N/A</p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="d-flex gap-3 align-items-baseline">
              <h2 class="f-16 small-title mb-0">Last Message</h2>
              <p class="f-16 text-muted mb-0" v-if="chatMessagesData.length>0">
                {{ DateWithMonthName(chatMessagesData.slice(-1).created_at) }}
              </p>
              <p class="f-16 text-muted mb-0" v-else>
                N/A
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div>
              <h2 class="f-16 small-title mb-2">Description</h2>
              <p class="f-16 text-muted mb-0">
                {{ getSafe(() => ticket.detail) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card border">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mb-0">
            <h2 class="f-22 fw-bold mb-0">Attachments</h2>
          </div>
        </div>
        <hr class="custom-divider" />
        <div class="row">
          <div class="col-md-4 mb-3" v-if="ticketImageUrl">
            <div class="border">
              <div>
                  <i class="uil uil-paperclip primaryColor"
                    @click="dataForModal = ticket.attachment"
                    v-b-modal.modal-attachment
                  > View Attachment </i>
                <!-- <img :src="ticketImageUrl" class="img-fluid" alt="" /> -->
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center fw-bold">No Attachment Found</div>
          </div>
        </div>
      </div>
    </div>
    <ChatWidget :chatMessagesData="chatMessagesData" :ticket="ticket" />

     <b-modal
      id="modal-attachment"
      size="lg"
      title="Attachment"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 border">
          <iframe
            :style="{ height: '400px' }"
            width="100%"
            :src="assets_url + ticket.attachment"
          >
          </iframe>
        </div>
      </div>
    </b-modal>

  </component>
</template>
