<template>
   <div v-if="processing" class="d-flex justify-content-center">
      <i class='fas fa-spinner fa-spin font-22'></i>
   </div>
</template>

<script>
export default {
   name: "Loader",
   props: {
      processing: {default: false},
   },
}
</script>

<style scoped>
</style>
